exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebote-js": () => import("./../../../src/pages/angebote.js" /* webpackChunkName: "component---src-pages-angebote-js" */),
  "component---src-pages-headofsounddesign-js": () => import("./../../../src/pages/headofsounddesign.js" /* webpackChunkName: "component---src-pages-headofsounddesign-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-studios-js": () => import("./../../../src/pages/studios.js" /* webpackChunkName: "component---src-pages-studios-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

